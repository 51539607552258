import Axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios"
import { buildParams } from "./helper"

const API_URL = process.env.VITE_API_URL

declare module "axios" {
  export interface AxiosRequestConfig {
    _retry?: boolean
    unhandled?: boolean
  }
}

export class HTTPError extends Error {
  constructor(
    public status: number,
    public cause: string,
  ) {
    super(cause)
  }
}
export class BaseClient {
  private baseUrl = API_URL
  private axios: AxiosInstance

  constructor() {
    this.axios = Axios.create({
      baseURL: this.baseUrl,
    })

    this.axios.interceptors.request.use((req: any) => {
      return req
    })

    this.axios.interceptors.response.use(
      (response: any) => response,
      this.onApiError,
    )
  }

  private onApiError = async (error: AxiosError) => {
    const originalRequest = error.config

    if (
      originalRequest &&
      !originalRequest._retry &&
      !originalRequest.unhandled
    ) {
      switch (error.response?.status) {
        case 401:
          //   return this.handleUnauthorized(error);
          return Promise.reject(error)
        case 502:
          //   return this.handleServerError(error);
          return Promise.reject(error)
      }
    }

    console.warn(error.response)
    return Promise.reject(error)
  }

  //   private handleUnauthorized = async (error: AxiosError) => {
  //     try {
  //       const originalRequest = error.config;
  //       originalRequest._retry = true;

  //       const token = await auth().currentUser?.getIdToken();

  //       originalRequest.headers['Authorization'] = this.setAccessToken(token || '');
  //       return this.axios(originalRequest);
  //     } catch (refreshError) {
  //       console.warn('refreshError', refreshError);
  //       return Promise.reject(error);
  //     }
  //   };

  //   private handleServerError = async (error: AxiosError) => {
  //     try {
  //       const res = await profileAPI.checkServerStatus();

  //       if (res) {
  //         const originalRequest = error.config;
  //         originalRequest._retry = true;
  //         return this.axios(originalRequest);
  //       }

  //       return Promise.reject(error);
  //     } catch (serverError) {
  //       console.warn('API ERROR', serverError);

  //       navigationService.reset(Routes.SERVER_ERROR_MODAL);

  //       return Promise.reject(error);
  //     }
  //   };

  setAccessToken = (token: string) => {
    const newToken = `Bearer ${token}`
    this.axios.defaults.headers.common.Authorization = newToken

    return newToken
  }

  get = async <T, K, C>(
    url: string,
    params?: K,
    config?: C,
  ): Promise<AxiosResponse<T>> => {
    const queryParams = params ? buildParams(params) : ""
    return this.axios.get(url + queryParams, {
      ...config,
    })
  }

  delete = async <T, K>(url: string, data?: K): Promise<AxiosResponse<T>> => {
    return this.axios.delete(url, { params: data })
  }

  post = async <T, K>(
    url: string,
    data?: K,
    config?: AxiosRequestConfig<K>,
  ): Promise<AxiosResponse<T>> => {
    return this.axios.post(url, data, config)
  }

  patch = async <T, K>(url: string, data?: K): Promise<AxiosResponse<T>> => {
    return this.axios.patch(url, data)
  }

  put = async <T, K>(url: string, data?: K): Promise<AxiosResponse<T>> => {
    return this.axios.put(url, data)
  }
}

export const baseApiClient = new BaseClient()
