import { baseApiClient, BaseClient } from "@/api/baseClient"
import { AxiosResponse } from "axios"

const urls = {
  getErrorByUuid: "/v1/error",
  updateErrorByUuid: "/v1/error",
  deleteErrorByUuid: "/v1/error",
  getAlErrors: "/v1/error",
  createError: "/v1/error",
  getErrorByPage: "/v1/error/page",
}

export class ErrorAPI {
  constructor(private api: BaseClient) {}

  getErrorByUuid = async (id: string) => {
    const result: AxiosResponse<any> = await this.api.get(
      urls.getErrorByUuid + `/${id}`,
    )

    return result
  }

  updateErrorByUuid = async (uuid: string, body: any) => {
    const result: AxiosResponse<any> = await this.api.put(
      urls.updateErrorByUuid + `/${uuid}`,
      body,
    )

    return result
  }

  deleteErrorByUuid = async (id: string) => {
    const result: AxiosResponse<any> = await this.api.delete(
      urls.deleteErrorByUuid + `?uuid=${id}`,
    )

    return result
  }

  getAlErrors = async () => {
    const result: AxiosResponse<any> = await this.api.get(urls.getAlErrors)

    return result
  }

  createError = async (body: any) => {
    const result: AxiosResponse<any> = await this.api.post(
      urls.createError,
      body,
    )

    return result
  }

  getErrorByPage = async (params: any) => {
    const result: AxiosResponse<any> = await this.api.get(
      urls.getErrorByPage,
      params,
    )

    return result
  }
}

export const errorAPI = new ErrorAPI(baseApiClient)
