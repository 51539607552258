import { ConfigProvider } from "antd"
import HomeScreen from "./pages/Home"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { NotificationProvider } from "./hooks/useNotification"

const queryClient = new QueryClient()

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          // Seed Token
          colorPrimary: "#00b96b",
          borderRadius: 2,
        },
      }}
    >
      <QueryClientProvider client={queryClient}>
        <NotificationProvider>
          <HomeScreen />
          <ReactQueryDevtools initialIsOpen={false} />
        </NotificationProvider>
      </QueryClientProvider>
    </ConfigProvider>
  )
}

export default App
