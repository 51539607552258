import { DownOutlined, SearchOutlined } from "@ant-design/icons"
import { AutoComplete, AutoCompleteProps, Spin } from "antd"
import { throttle } from "lodash"
import { useMemo, useState, useCallback } from "react"

interface AutoCompleteInputProps extends AutoCompleteProps {
  fetching?: boolean
  isNextPage?: boolean
  infiniteScroll?: boolean
  onScroll?: () => void
  notFormatedValue?: boolean
}

const AutoCompleteInput = ({
  fetching = false,
  infiniteScroll,
  isNextPage,
  onScroll,
  notFormatedValue,
  ...props
}: AutoCompleteInputProps) => {
  const [isFocus, setIsFocus] = useState(false)

  const suffix = useMemo(
    () =>
      fetching ? (
        <Spin size="small" />
      ) : isFocus ? (
        <SearchOutlined />
      ) : (
        <DownOutlined />
      ),
    [fetching, isFocus],
  )

  const handleScroll = useCallback(
    throttle((e: any) => {
      const currentTarget = e.target
      if (!currentTarget) return
      const { scrollTop, clientHeight, scrollHeight } = e.target
      if (
        scrollTop + clientHeight >= scrollHeight - 20 &&
        !fetching &&
        isNextPage
      ) {
        onScroll?.()
      }
    }, 200),
    [fetching, isNextPage, onScroll],
  )

  const notFoundContent = useMemo(
    () =>
      fetching ? (
        <Spin size="small" />
      ) : props?.options?.length === 0 ? (
        "Not Found"
      ) : null,
    [fetching, props?.options],
  )

  const value = useMemo(() => {
    if (notFormatedValue) {
      return props.value
    }
    return props?.options?.find((option) => option.value === props.value)?.label
  }, [props?.options, props.value, notFormatedValue])

  return (
    <AutoComplete
      onFocus={() => setIsFocus(true)}
      onBlur={() => setIsFocus(false)}
      onPopupScroll={infiniteScroll ? handleScroll : undefined}
      suffixIcon={suffix}
      value={value}
      notFoundContent={notFoundContent}
      {...props}
    />
  )
}

export default AutoCompleteInput
