import ProjectList from "./ProjectList"
import MicroserviceList from "./MicroserviceList"
import ErrorList from "./ErrorList"

const HomeScreen = () => {
  return (
    <div className="grid max-h-screen grid-cols-1_2_3 gap-3 p-5">
      <ProjectList />
      <MicroserviceList />
      <ErrorList />
    </div>
  )
}

export default HomeScreen
