export const formatOptionsWithContent = (
  data: any[] = [],
  valueKey: string,
  labelKey: string,
): { value: string; label: string }[] => {
  return data.map((item) => ({
    value: String(item[valueKey]),
    label: item[labelKey],
  }))
}

export const formatOptionsForProjectSelect = (
  data: any[] = [],
  valueKey: string,
  labelKey: string,
): { value: string; label: string }[] => {
  return data.map((item) => ({
    value: String(item[valueKey]),
    label: `ID: ${item.id} - ${item[labelKey]}`,
  }))
}

export const formatOptionsForMicroserviceSelect = (
  data: any[] = [],
  renderTitle: (item: any) => JSX.Element,
) => {
  return data.map((item) => ({
    value: String(item.id),
    label: renderTitle(item),
  }))
}
