import { errorAPI } from "@/api/requests/v1/errorAPI"
import { useInfiniteQuery, useMutation } from "./useQuery"
import { useCustomNotification } from "./useNotification"

type BodyType = {
  page: number
  size: number
  sort?: string[]
  query?: string
}

type ErrorHookProps = {
  body: BodyType
  initialBody: BodyType
}

export const useErrorQuery = ({ body, initialBody }: ErrorHookProps) => {
  const {
    data,
    isLoading,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    isFetchingNextPage,
    isFetchingPreviousPage,
    isFetching,
    isError,
    refetch,
    error,
  } = useInfiniteQuery({
    queryKey: ["getAllErrors", body],
    queryFn: async ({ pageParam }: any) => {
      const res = await errorAPI.getErrorByPage(pageParam)
      return res.data
    },
    initialPageParam: {
      page: body.page,
      sort: body.sort,
      query: body.query,
      size: body.size,
    },
    getNextPageParam: (lastPage) => {
      const { currentPage, totalPages } = lastPage.result
      if (currentPage < totalPages - 1) {
        return {
          ...initialBody,
          page: currentPage + 1,
          sort: body.sort,
          query: body.query,
        }
      } else {
        return undefined
      }
    },
    getPreviousPageParam: (lastPage) => {
      const { currentPage } = lastPage.result
      if (currentPage > 0) {
        return {
          ...initialBody,
          page: currentPage - 1,
          sort: body.sort,
          query: body.query,
        }
      } else {
        return undefined
      }
    },
  })

  return {
    data: data?.pages.map((page) => page.result.content),
    isLoading,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    isFetchingNextPage,
    isFetchingPreviousPage,
    isFetching,
    refetch,
    error,
    isError,
  }
}

type CreateErrorHookProps = {
  onSuccess: () => void
  onError?: () => void
}

export const useCreateError = ({
  onSuccess,
  onError,
}: CreateErrorHookProps) => {
  const { openNotification } = useCustomNotification()
  const { mutate, isPending, data } = useMutation({
    mutationKey: ["createError"],
    mutationFn: async (body: any) => {
      const res = await errorAPI.createError(body)
      return res.data
    },
    onSuccess: () => {
      onSuccess()
      openNotification("success", "Success", "Error created successfully")
    },
    onError: (error: any) => {
      openNotification(
        "error",
        error?.response?.data?.error?.code,
        error?.response?.data?.error?.message.ru,
      )
      onError && onError()
    },
  })

  return {
    mutate,
    isPending,
    data,
  }
}

export const useDeleteError = ({ onSuccess }: CreateErrorHookProps) => {
  const { openNotification } = useCustomNotification()
  const { mutate, isPending } = useMutation({
    mutationKey: ["deleteError"],
    mutationFn: async (body: any) => {
      const res = await errorAPI.deleteErrorByUuid(body)
      return res.data
    },
    onSuccess: () => {
      onSuccess()
      openNotification("success", "Success", "Error deleted successfully")
    },
    onError: (error: any) => {
      openNotification(
        "error",
        error?.response?.data?.error?.code,
        error?.response?.data?.error?.message.ru,
      )
    },
  })

  return {
    mutate,
    isPending,
  }
}

export const useUpdateError = ({ onSuccess }: CreateErrorHookProps) => {
  const { openNotification } = useCustomNotification()
  const { mutate, isPending } = useMutation({
    mutationKey: ["updateError"],
    mutationFn: async (body: any) => {
      const res = await errorAPI.updateErrorByUuid(body.oldUuid, body.body)
      return res.data
    },
    onSuccess: () => {
      onSuccess()
      openNotification("success", "Success", "Error updated successfully")
    },
    onError: (error: any) => {
      openNotification(
        "error",
        error?.response?.data?.error?.code,
        error?.response?.data?.error?.message.ru,
      )
    },
  })

  return {
    updateError: mutate,
    updateErrorIsPending: isPending,
  }
}
