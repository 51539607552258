import { microserviceAPI } from "@/api/requests/v1/microserviceAPI"
import { useInfiniteQuery, useMutation } from "./useQuery"
import { useCustomNotification } from "./useNotification"

type BodyType = {
  page: number
  size: number
  sort?: string[]
  query?: string
}

type MicroserviceHookProps = {
  body: BodyType
  initialBody: BodyType
}

export const useMicroserviceQuery = ({
  body,
  initialBody,
}: MicroserviceHookProps) => {
  const {
    data,
    isLoading,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    isFetchingNextPage,
    isFetchingPreviousPage,
    isFetching,
    refetch,
    error,
  } = useInfiniteQuery({
    queryKey: ["getAllMicroservices", body],
    queryFn: async ({ pageParam }: any) => {
      const res = await microserviceAPI.getMicroserviceByPage(pageParam)
      return res.data
    },
    initialPageParam: {
      page: body.page,
      sort: body.sort,
      query: body.query,
      size: body.size,
    },
    getNextPageParam: (lastPage) => {
      const { currentPage, totalPages } = lastPage.result
      if (currentPage < totalPages - 1) {
        return {
          ...initialBody,
          page: currentPage + 1,
          sort: body.sort,
          query: body.query,
        }
      } else {
        return undefined
      }
    },
    getPreviousPageParam: (lastPage) => {
      const { currentPage } = lastPage.result
      if (currentPage > 0) {
        return {
          ...initialBody,
          page: currentPage - 1,
          sort: body.sort,
          query: body.query,
        }
      } else {
        return undefined
      }
    },
  })

  return {
    data: data?.pages.map((page) => page.result.content),
    isLoading,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    isFetchingNextPage,
    isFetchingPreviousPage,
    isFetching,
    refetch,
    error,
  }
}

type CreateMicroserviceHookProps = {
  onSuccess: () => void
  onError?: () => void
}

export const useCreateMicroservice = ({
  onSuccess,
}: CreateMicroserviceHookProps) => {
  const { openNotification } = useCustomNotification()
  const { mutate, isPending } = useMutation({
    mutationKey: ["createMicroservice"],
    mutationFn: async (body: any) => {
      const res = await microserviceAPI.createMicroservice(body)
      return res.data
    },
    onSuccess: () => {
      onSuccess()
      openNotification(
        "success",
        "Success",
        "Microservice created successfully",
      )
    },
    onError: (error: any) => {
      openNotification(
        "error",
        error?.response?.data?.error?.code,
        error?.response?.data?.error?.message.ru,
      )
    },
  })

  return {
    mutate,
    isPending,
  }
}

export const useDeleteMicroservice = ({
  onSuccess,
  onError,
}: CreateMicroserviceHookProps) => {
  const { openNotification } = useCustomNotification()
  const { mutate, isPending } = useMutation({
    mutationKey: ["deleteMicroservice"],
    mutationFn: async (id: string) => {
      const res = await microserviceAPI.deleteMicroserviceById(id)
      return res.data
    },
    onSuccess: () => {
      onSuccess()
      openNotification(
        "success",
        "Success",
        "Microservice deleted successfully",
      )
    },
    onError: (error: any) => {
      onError && onError()
      openNotification(
        "error",
        error?.response?.data?.error?.code,
        error?.response?.data?.error?.message.ru,
      )
    },
  })

  return {
    deleteMicroservice: mutate,
    deleteMicroserviceIsPending: isPending,
  }
}

export const useUpdateMicroservice = ({
  onSuccess,
  onError,
}: CreateMicroserviceHookProps) => {
  const { openNotification } = useCustomNotification()
  const { mutate, isPending } = useMutation({
    mutationKey: ["updateMicroservice"],
    mutationFn: async (body: any) => {
      const res = await microserviceAPI.updateMicroserviceById(
        body.oldId,
        body.body,
      )
      return res.data
    },
    onSuccess: () => {
      onSuccess()
      openNotification(
        "success",
        "Success",
        "Microservice updated successfully",
      )
    },
    onError: (error: any) => {
      onError && onError()
      openNotification(
        "error",
        error?.response?.data?.error?.code,
        error?.response?.data?.error?.message.ru,
      )
    },
  })

  return {
    updateMicroservice: mutate,
    updateMicroserviceIsPending: isPending,
  }
}
