import { Button, Flex, Input } from "antd"

type HeaderListProps = {
  onSearch: (value: string) => void
  defaultSearchValue: string
  onPress: () => void
  titleBtn: string
  className?: string
  title?: string
}

const HeaderList = ({
  onSearch,
  defaultSearchValue,
  onPress,
  titleBtn,
  className,
  title,
}: HeaderListProps) => {
  return (
    <Flex gap="middle" className={className}>
      <h1 className="mb-3 text-xl font-medium">{title}</h1>
      <Input.Search
        placeholder="Search"
        onSearch={onSearch}
        defaultValue={defaultSearchValue}
      />
      <Button type="primary" onClick={onPress}>
        {titleBtn}
      </Button>
    </Flex>
  )
}

export default HeaderList
