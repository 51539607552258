import { baseApiClient, BaseClient } from "@/api/baseClient"
import { AxiosResponse } from "axios"

const urls = {
  updateMicroserviceById: "/v1/microservice",
  deleteMicroserviceById: "/v1/microservice",
  getAllMicroservices: "/v1/microservice",
  createMicroservice: "/v1/microservice",
  getMicroserviceByPage: "/v1/microservice/page",
}

export class MicroserviceAPI {
  constructor(private api: BaseClient) {}

  updateMicroserviceById = async (id: string, body: any) => {
    const result: AxiosResponse<any> = await this.api.put(
      urls.updateMicroserviceById + `/${id}`,
      body,
    )

    return result
  }

  deleteMicroserviceById = async (id: string) => {
    const result: AxiosResponse<any> = await this.api.delete(
      urls.deleteMicroserviceById + `/${id}`,
    )

    return result
  }

  getAllMicroservices = async () => {
    const result: AxiosResponse<any> = await this.api.get(
      urls.getAllMicroservices,
    )

    return result
  }

  createMicroservice = async (body: any) => {
    const result: AxiosResponse<any> = await this.api.post(
      urls.createMicroservice,
      body,
    )

    return result
  }

  getMicroserviceByPage = async (params: any) => {
    const result: AxiosResponse<any> = await this.api.get(
      urls.getMicroserviceByPage,
      params,
    )

    return result
  }
}

export const microserviceAPI = new MicroserviceAPI(baseApiClient)
