import { baseApiClient, BaseClient } from "@/api/baseClient"
import { AxiosResponse } from "axios"

const urls = {
  updateProjectById: "/v1/project",
  deleteProjectById: "/v1/project",
  getAllProjects: "/v1/project",
  createProject: "/v1/project",
  getProjectByPage: "/v1/project/page",
}

export class ProjectAPI {
  constructor(private api: BaseClient) {}

  updateProjectById = async (body: any) => {
    const result: AxiosResponse<any> = await this.api.put(
      urls.updateProjectById + `/${body.id}`,
      {
        name: body.name,
      },
    )

    return result
  }

  deleteProjectById = async (id: string) => {
    const result: AxiosResponse<any> = await this.api.delete(
      urls.deleteProjectById + `/${id}`,
    )

    return result
  }

  getAllProjects = async () => {
    const result: AxiosResponse<any> = await this.api.get(urls.getAllProjects)

    return result
  }

  createProject = async (body: any) => {
    const result: AxiosResponse<any> = await this.api.post(
      urls.createProject,
      body,
    )

    return result
  }

  getProjectByPage = async (params: any) => {
    const result: AxiosResponse<any> = await this.api.get(
      urls.getProjectByPage,
      params,
    )

    return result
  }
}

export const projectAPI = new ProjectAPI(baseApiClient)
