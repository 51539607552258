import HeaderList from "@/components/HeaderList"
import {
  useCreateProject,
  useDeleteProject,
  useProjectQuery,
  useUpdateProject,
} from "@/hooks/useProjectQuery"
import { DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { Button, Divider, Form, Input, Modal, Skeleton, Table } from "antd"
import { useCallback, useState } from "react"
import InfiniteScroll from "react-infinite-scroll-component"

const ProjectList = () => {
  const initialBody = {
    page: 0,
    size: 10,
    sort: [""],
    query: "",
  }
  const [form] = Form.useForm()
  const [body, setBody] = useState(initialBody)
  const [open, setOpen] = useState(false)
  const [update, setUpdate] = useState(null)

  const { data, isLoading, refetch, hasNextPage, fetchNextPage } =
    useProjectQuery({ body, initialBody })

  const { mutate, isPending } = useCreateProject({
    onSuccess: () => {
      refetch()
      form.resetFields()
      setOpen(false)
      setBody(initialBody)
    },
    onError: () => {},
  })

  const { mutate: mutateDelete } = useDeleteProject({
    onSuccess: () => {
      refetch()
    },
  })

  const { mutate: mutateUpdate, isPending: isUpdatePending } = useUpdateProject(
    {
      onSuccess: () => {
        refetch()
        form.resetFields()
        setOpen(false)
        setUpdate(null)
      },
      onError: () => {},
    },
  )

  const onFinish = useCallback(
    (values: any) => {
      if (update) {
        mutateUpdate({ id: update, name: values.name })
      } else {
        mutate(values)
      }
    },
    [mutate, mutateUpdate, update],
  )

  const onEdit = (project: any) => {
    form.setFieldsValue({ name: project.name })
    setOpen(true)
    setUpdate(project.id)
  }

  const getColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 60,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      render: (_: any, record: any) => (
        <div className="flex gap-3">
          <Button
            onClick={() => mutateDelete(record.id)}
            shape="circle"
            icon={<DeleteOutlined />}
          />
          <Button
            onClick={() => onEdit(record)}
            shape="circle"
            icon={<EditOutlined />}
          />
        </div>
      ),
    },
  ]

  return (
    <div>
      <HeaderList
        onSearch={(value) => setBody({ ...body, query: value })}
        defaultSearchValue={body.query}
        onPress={() => setOpen(true)}
        titleBtn="Add"
        title="Projects"
      />
      <div
        style={{
          height: "calc(100vh - 64px)",
          padding: "0 16px",
          paddingBottom: "40px",
          paddingTop: "16px",
          border: "1px solid rgba(140, 140, 140, 0.35)",
          borderRadius: "4px",
          backgroundColor: "#fff",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.09)",
        }}
      >
        <div
          id="scrollableDiv2"
          style={{
            height: "100%",
            overflow: "auto",
            marginTop: "5px",
          }}
        >
          <InfiniteScroll
            dataLength={data?.flat().length || 0}
            next={fetchNextPage}
            hasMore={hasNextPage}
            loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
            endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
            scrollableTarget="scrollableDiv2"
          >
            <Table
              dataSource={data?.flat()}
              columns={getColumns}
              loading={isLoading}
              pagination={false}
              scroll={{ scrollToFirstRowOnChange: false }}
              rowKey="id"
            />
          </InfiniteScroll>
          <Modal
            open={open}
            title="Create a new project"
            okText="Create"
            cancelText="Cancel"
            okButtonProps={{
              autoFocus: true,
              htmlType: "submit",
              loading: isPending || isUpdatePending,
            }}
            forceRender
            onCancel={() => {
              setOpen(false)
              form.resetFields()
              setUpdate(null)
            }}
            onClose={() => {
              setOpen(false)
              form.resetFields()
              setUpdate(null)
            }}
            destroyOnClose
            modalRender={(dom) => (
              <Form
                layout="vertical"
                form={form}
                name="project_form"
                clearOnDestroy
                onFinish={onFinish}
              >
                {dom}
              </Form>
            )}
          >
            <Form.Item
              name="name"
              label="Project Name"
              rules={[
                {
                  required: true,
                  message: "Please input the project name!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Modal>
        </div>
      </div>
    </div>
  )
}

export default ProjectList
