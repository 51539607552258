import { projectAPI } from "@/api/requests/v1/projectsAPI"
import { useInfiniteQuery } from "@tanstack/react-query"
import { useMutation } from "./useQuery"
import { useCustomNotification } from "./useNotification"

type BodyType = {
  page: number
  size: number
  sort?: string[]
  query?: string
}

type ProjectHookProps = {
  body: BodyType
  initialBody: BodyType
}

export const useProjectQuery = ({ body, initialBody }: ProjectHookProps) => {
  const {
    data,
    isLoading,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    isFetchingNextPage,
    isFetchingPreviousPage,
    isFetching,
    refetch,
    error,
  } = useInfiniteQuery({
    queryKey: ["getAllProjects", body],
    queryFn: async ({ pageParam }: any) => {
      const res = await projectAPI.getProjectByPage(pageParam)
      return res.data
    },
    initialPageParam: {
      page: body.page,
      sort: body.sort,
      query: body.query,
      size: body.size,
    },
    getNextPageParam: (lastPage) => {
      const { currentPage, totalPages } = lastPage.result
      if (currentPage < totalPages - 1) {
        return {
          ...initialBody,
          page: currentPage + 1,
          sort: body.sort,
          query: body.query,
        }
      } else {
        return undefined
      }
    },
    getPreviousPageParam: (lastPage) => {
      const { currentPage } = lastPage.result
      if (currentPage > 0) {
        return {
          ...initialBody,
          page: currentPage - 1,
          sort: body.sort,
          query: body.query,
        }
      } else {
        return undefined
      }
    },
  })

  return {
    data: data?.pages.map((page) => page.result.content),
    isLoading,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    isFetchingNextPage,
    isFetchingPreviousPage,
    isFetching,
    refetch,
    error,
  }
}

type CreateProjectHookProps = {
  onSuccess?: () => void
  onError?: () => void
}

export const useCreateProject = ({
  onSuccess,
  onError,
}: CreateProjectHookProps) => {
  const { openNotification } = useCustomNotification()
  const { mutate, isPending, data } = useMutation({
    mutationKey: ["createProject"],
    mutationFn: async (body: any) => {
      const res = await projectAPI.createProject(body)
      return res.data
    },
    onSuccess: () => {
      onSuccess && onSuccess()
      openNotification("success", "Success", "Project successfully created")
    },
    onError: (error: any) => {
      onError && onError()
      openNotification(
        "error",
        error?.response?.data?.error?.code,
        error?.response?.data?.error?.message.ru,
      )
    },
  })

  return {
    mutate,
    isPending,
    data,
  }
}

export const useDeleteProject = ({
  onSuccess,
  onError,
}: CreateProjectHookProps) => {
  const { openNotification } = useCustomNotification()

  const { mutate, isPending, data, error } = useMutation({
    mutationKey: ["deleteProject"],
    mutationFn: async (body: any) => {
      const res = await projectAPI.deleteProjectById(body)
      return res.data
    },
    onSuccess: () => {
      onSuccess && onSuccess()
      openNotification("success", "Success", "Project successfully deleted")
    },
    onError: (error: any) => {
      onError && onError()
      openNotification(
        "error",
        error?.response?.data?.error?.code,
        error?.response?.data?.error?.message.ru,
      )
    },
  })

  return {
    mutate,
    isPending,
    data,
    error,
  }
}

export const useUpdateProject = ({
  onSuccess,
  onError,
}: CreateProjectHookProps) => {
  const { openNotification } = useCustomNotification()
  const { mutate, isPending, data } = useMutation({
    mutationKey: ["updateProject"],
    mutationFn: async (body: any) => {
      const res = await projectAPI.updateProjectById(body)
      return res.data
    },
    onSuccess: () => {
      onSuccess && onSuccess()
      openNotification("success", "Success", "Project successfully updated")
    },
    onError: (error: any) => {
      onError && onError()
      openNotification(
        "error",
        "Error",
        error?.response?.data?.error?.message.ru,
      )
    },
  })

  return {
    mutate,
    isPending,
    data,
  }
}
