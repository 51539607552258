import AutoCompleteInput from "@/components/AutoCompleteInput"
import HeaderList from "@/components/HeaderList"
import { formatOptionsForMicroserviceSelect } from "@/helpers"
import {
  useCreateError,
  useDeleteError,
  useErrorQuery,
  useUpdateError,
} from "@/hooks/useErrorQuery"
import { useMicroserviceQuery } from "@/hooks/useMicroserviceQuery"
import { DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { Button, Form, Input, Modal, Select, Table } from "antd"
import { debounce } from "lodash"
import { useCallback, useState } from "react"

const ErrorList = () => {
  const initialBody = {
    page: 0,
    size: 20,
    sort: [""],
    query: "",
  }

  const [form] = Form.useForm()
  const [open, setOpen] = useState(false)
  const [body, setBody] = useState(initialBody)
  const [microBody, setMicroBody] = useState(initialBody)
  const [update, setUpdate] = useState<any>(null)

  const {
    data: microData,
    isLoading: microIsLoading,
    fetchNextPage: fetchNextPageMicro,
    hasNextPage: hasNextPageMicro,
  } = useMicroserviceQuery({
    body: microBody,
    initialBody,
  })

  const { data, refetch, isLoading, fetchNextPage, hasNextPage } =
    useErrorQuery({
      body,
      initialBody,
    })

  const { mutate, isPending } = useCreateError({
    onSuccess: () => {
      refetch()
      form.resetFields()
      setOpen(false)
      setBody(initialBody)
    },
  })

  const { mutate: deleteError } = useDeleteError({
    onSuccess: () => {
      refetch()
    },
  })

  const { updateError } = useUpdateError({
    onSuccess: () => {
      refetch()
      form.resetFields()
      setOpen(false)
      setUpdate(null)
    },
  })

  const onFinish = (values: any) => {
    if (update) {
      updateError({ oldUuid: update.uuid, body: values })
      return
    }
    mutate(values)
  }

  const handleSearchMicro = (value: string) => {
    setMicroBody({ ...microBody, query: value })
  }

  const debouncedHandleSearchMicro = useCallback(
    debounce(handleSearchMicro, 300),
    [],
  )

  const onEdit = (error: any) => {
    form.setFieldsValue({
      microserviceId: error.microservice.id,
      httpStatus: error.httpStatus,
      errorId: error.errorId,
      messageUz: error.messageUz,
      messageRu: error.messageRu,
      messageEn: error.messageEn,
      description: error.description,
      loggable: error.loggable ? "true" : "false",
      alertable: error.alertable ? "true" : "false",
    })
    setOpen(true)
    setUpdate(error)
  }

  const renderTitle = (item: any) => (
    <div className="flex flex-col gap-1 border-b-[1px] py-2">
      <div>Microservices: {item.name}</div>
      <div>Microservices ID: {item.id}</div>
      <div>Project ID: {item.project.id}</div>
      <div>Project Name: {item.project.name}</div>
    </div>
  )

  const getColumn = [
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      width: 90,
    },
    {
      title: "Microservices",
      dataIndex: "microservice",
      key: "microservice",
      render: (item: any) => (
        <p>
          {item.name}: {item.id}
        </p>
      ),
    },
    {
      title: "Project",
      dataIndex: "microservice",
      key: "projects",
      render: (item: any) => (
        <p>
          {item.project.name}: {item.project.id}
        </p>
      ),
    },
    {
      title: "Messages",
      dataIndex: "messageEn",
      key: "messageEn",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "HTTP Status",
      dataIndex: "httpStatus",
      key: "httpStatus",
    },
    {
      title: "Loggable",
      dataIndex: "loggable",
      key: "loggable",
      render: (item: any) => <p>{item.loggable ? "True" : "False"}</p>,
    },
    {
      title: "Alertable",
      dataIndex: "alertable",
      key: "alertable",
      render: (item: any) => <p>{item.alertable ? "True" : "False"}</p>,
    },
    {
      title: "",
      dataIndex: "delete",
      key: "delete",
      render: (_: any, record: any) => (
        <div className="flex gap-3">
          <Button
            onClick={() => deleteError(record.uuid)}
            shape="circle"
            icon={<DeleteOutlined />}
          />
          <Button
            onClick={() => onEdit(record)}
            shape="circle"
            icon={<EditOutlined />}
          />
        </div>
      ),
    },
  ]

  return (
    <div>
      <HeaderList
        onSearch={(value) => setBody({ ...body, query: value })}
        defaultSearchValue={body.query}
        onPress={() => setOpen(true)}
        titleBtn="Add"
        title="Errors"
      />
      <div
        style={{
          height: "calc(100vh - 64px)",
          padding: "0 16px",
          paddingBottom: "40px",
          paddingTop: "16px",
          border: "1px solid rgba(140, 140, 140, 0.35)",
          borderRadius: "4px",
          backgroundColor: "#fff",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.09)",
        }}
      >
        <Table
          dataSource={data?.flat()}
          columns={getColumn}
          loading={isLoading}
          pagination={false}
          rowKey="uuid"
          scroll={{ y: "calc(100vh - 230px", scrollToFirstRowOnChange: true }}
          onScroll={(e) => {
            const target = e.target as HTMLDivElement
            if (
              target.scrollHeight - target.scrollTop === target.clientHeight &&
              hasNextPage
            ) {
              fetchNextPage()
            }
          }}
        />
        <Modal
          open={open}
          title="Create a new Error"
          okText="Create"
          cancelText="Cancel"
          okButtonProps={{
            form: "error_form",
            autoFocus: true,
            htmlType: "submit",
            loading: isPending,
          }}
          onCancel={() => {
            setOpen(false)
            form.resetFields()
            setUpdate(null)
          }}
          onClose={() => {
            setOpen(false)
            form.resetFields()
            setUpdate(null)
          }}
          destroyOnClose
          classNames={{
            content: "pb-10",
            footer: "pb-10",
          }}
        >
          <Form
            layout="vertical"
            form={form}
            name="error_form"
            id="error_form"
            onFinish={onFinish}
          >
            <Form.Item
              name="microserviceId"
              label="Select Microservice"
              rules={[
                {
                  required: true,
                  message: "Please input the microservice name!",
                },
              ]}
            >
              <AutoCompleteInput
                placeholder="Search Microservice"
                options={formatOptionsForMicroserviceSelect(
                  microData?.flat(),
                  renderTitle,
                )}
                fetching={microIsLoading}
                onSearch={(value) => debouncedHandleSearchMicro(value)}
                infiniteScroll
                onScroll={fetchNextPageMicro}
                isNextPage={hasNextPageMicro}
                notFormatedValue
              />
            </Form.Item>
            <Form.Item
              name="httpStatus"
              label="HTTP Status"
              rules={[
                {
                  required: true,
                  message: "Please input the http status!",
                },
              ]}
            >
              <Input placeholder="HTTP Status" />
            </Form.Item>
            <Form.Item
              name="errorId"
              label="Error ID"
              rules={[
                {
                  required: true,
                  message: "Please input the error ID!",
                },
              ]}
            >
              <Input placeholder="Error ID" />
            </Form.Item>
            <Form.Item
              name="messageUz"
              label="Message name in Uzbek"
              rules={[
                {
                  required: true,
                  message: "Please input the message name in Uzbek!",
                },
              ]}
            >
              <Input placeholder="Message name in Uzbek" />
            </Form.Item>
            <Form.Item
              name="messageRu"
              label="Message name in Russian"
              rules={[
                {
                  required: true,
                  message: "Please input the message name in Russian!",
                },
              ]}
            >
              <Input placeholder="Message name in Russian" />
            </Form.Item>
            <Form.Item
              name="messageEn"
              label="Message name in English"
              rules={[
                {
                  required: true,
                  message: "Please input the message name in English!",
                },
              ]}
            >
              <Input placeholder="Message name in English" />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description of Error"
              rules={[
                {
                  required: true,
                  message: "Please input the description of error!",
                },
              ]}
            >
              <Input.TextArea placeholder="Description of Error" />
            </Form.Item>
            <Form.Item
              name="loggable"
              label="Loggable"
              rules={[
                {
                  required: true,
                  message: "Please input the loggable!",
                },
              ]}
            >
              <Select
                placeholder="Select a loggable"
                options={[
                  { value: "true", label: "True" },
                  { value: "false", label: "False" },
                ]}
              />
            </Form.Item>
            <Form.Item
              name="alertable"
              label="Alertable"
              rules={[
                {
                  required: true,
                  message: "Please input the alertable!",
                },
              ]}
            >
              <Select
                placeholder="Select a alertable"
                options={[
                  { value: "true", label: "True" },
                  { value: "false", label: "False" },
                ]}
              />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </div>
  )
}

export default ErrorList
