import AutoCompleteInput from "@/components/AutoCompleteInput"
import HeaderList from "@/components/HeaderList"
import { formatOptionsForProjectSelect } from "@/helpers"
import {
  useCreateMicroservice,
  useDeleteMicroservice,
  useMicroserviceQuery,
  useUpdateMicroservice,
} from "@/hooks/useMicroserviceQuery"
import { useProjectQuery } from "@/hooks/useProjectQuery"
import { DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { Button, Divider, Form, Input, Modal, Skeleton, Table } from "antd"
import { debounce } from "lodash"
import { useCallback, useState } from "react"
import InfiniteScroll from "react-infinite-scroll-component"

const MicroserviceList = () => {
  const initialBody = {
    page: 0,
    size: 10,
    sort: [""],
    query: "",
  }

  const [form] = Form.useForm()
  const [open, setOpen] = useState(false)
  const [body, setBody] = useState(initialBody)
  const [update, setUpdate] = useState<any>(null)
  const [projectBody, setProjectBody] = useState(initialBody)

  const {
    data: projectData,
    isLoading: projectIsLoading,
    fetchNextPage,
    hasNextPage,
  } = useProjectQuery({
    body: projectBody,
    initialBody,
  })
  const {
    data,
    isLoading,
    refetch,
    hasNextPage: hasNextPageMicro,
    fetchNextPage: fetchNextPageMicro,
  } = useMicroserviceQuery({
    body,
    initialBody,
  })

  const { mutate, isPending } = useCreateMicroservice({
    onSuccess: () => {
      refetch()
      form.resetFields()
      setOpen(false)
      setBody(initialBody)
    },
  })

  const { deleteMicroservice } = useDeleteMicroservice({
    onSuccess: () => {
      refetch()
    },
  })

  const { updateMicroservice } = useUpdateMicroservice({
    onSuccess: () => {
      refetch()
      form.resetFields()
      setOpen(false)
      setUpdate(null)
    },
  })

  const onFinish = useCallback(
    (values: any) => {
      if (update) {
        updateMicroservice({
          oldId: update,
          body: values,
        })
        return
      }
      mutate(values)
    },
    [mutate, update, updateMicroservice],
  )

  const onEdit = (microservice: any) => {
    console.log(microservice)
    form.setFieldsValue({
      projectId: microservice.project.id,
      name: microservice.name,
    })
    setOpen(true)
    setUpdate(microservice.id)
  }

  const getColumn = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 60,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Project",
      dataIndex: "project",
      key: "project",
      render: (project: any) => (
        <div>
          {project?.name}: {project?.id}
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      render: (_: any, record: any) => (
        <div className="flex gap-3">
          <Button
            onClick={() => deleteMicroservice(record.id)}
            shape="circle"
            icon={<DeleteOutlined />}
          />
          <Button
            onClick={() => onEdit(record)}
            shape="circle"
            icon={<EditOutlined />}
          />
        </div>
      ),
    },
  ]

  const handleSearch = (value: string) => {
    setProjectBody({ ...projectBody, query: value })
  }

  const debouncedHandleSearch = useCallback(debounce(handleSearch, 300), [])

  return (
    <div>
      <HeaderList
        onSearch={(value) => setBody({ ...body, query: value })}
        defaultSearchValue={body.query}
        onPress={() => setOpen(true)}
        titleBtn="Add"
        title="Microservices"
      />
      <div
        style={{
          height: "calc(100vh - 64px)",
          padding: "0 16px",
          paddingBottom: "40px",
          paddingTop: "16px",
          border: "1px solid rgba(140, 140, 140, 0.35)",
          borderRadius: "4px",
          backgroundColor: "#fff",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.09)",
        }}
      >
        <div
          id="scrollableDiv"
          style={{
            height: "100%",
            overflow: "auto",
            marginTop: "5px",
          }}
        >
          <InfiniteScroll
            dataLength={data?.flat().length || 0}
            next={fetchNextPageMicro}
            hasMore={hasNextPageMicro}
            loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
            endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
            scrollableTarget="scrollableDiv"
          >
            <Table
              dataSource={data?.flat()}
              columns={getColumn}
              loading={isLoading}
              pagination={false}
              scroll={{ scrollToFirstRowOnChange: false }}
              rowKey="id"
            />
          </InfiniteScroll>
          <Modal
            open={open}
            title="Create a new Microservice"
            okText="Create"
            cancelText="Cancel"
            okButtonProps={{
              form: "microserviceForm",
              autoFocus: true,
              htmlType: "submit",
              loading: isPending,
            }}
            onCancel={() => {
              setOpen(false)
              form.resetFields()
              setUpdate(null)
            }}
            onClose={() => {
              setOpen(false)
              form.resetFields()
              setUpdate(null)
            }}
            destroyOnClose
          >
            <Form
              layout="vertical"
              form={form}
              name="microservice_form"
              id="microserviceForm"
              onFinish={onFinish}
            >
              <Form.Item
                name="projectId"
                label="Project Name"
                rules={[
                  {
                    required: true,
                    message: "Please input the project name!",
                  },
                ]}
              >
                <AutoCompleteInput
                  placeholder="Project Name"
                  options={formatOptionsForProjectSelect(
                    projectData?.flat(),
                    "id",
                    "name",
                  )}
                  fetching={projectIsLoading}
                  onSearch={(value) => debouncedHandleSearch(value)}
                  infiniteScroll
                  onScroll={fetchNextPage}
                  isNextPage={hasNextPage}
                />
              </Form.Item>
              <Form.Item
                name="name"
                label="Microservice Name"
                rules={[
                  {
                    required: true,
                    message: "Please input the microservice name!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Form>
          </Modal>
        </div>
      </div>
    </div>
  )
}

export default MicroserviceList
